import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';

import device from '../helpers/breakpoints';
import Layout from '../components/layout';
import Nav from '../components/nav';
import Footer from '../components/footer';

export const query = graphql`
  {
    site {
      siteMetadata {
        name
        title
        description
      }
    }
    image: file(relativePath: { eq: "404.png" }) {
      publicURL
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const BodyContainer = styled.div`
  max-width: 728px;
  padding: 0 1rem;
  margin: 8rem auto 4rem;

  @media ${device.tablet} {
    padding: 0 2rem;
    max-width: 960px;
  }

  @media ${device.laptop} {
    padding: 0 8rem;
    max-width: 1280px;
  }

  @media ${device.laptopL} {
    padding: 0 8rem;
    max-width: 1440px;
  }

  h1 {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.022em;
  }

  h2 {
    font-size: 26px;
    line-height: 1.18;
    letter-spacing: -0.022em;
    margin-top: 1.72em;

    + p {
      margin-top: 0.46em;
    }
  }

  p {
    font-size: 21px;
    line-height: 1.58;
    letter-spacing: -0.004em;
    margin-top: 2em;
  }
`;

const Image = styled.img`
  width: 100%;
  max-width: 900px;
  height: auto;
`;

const FlexWrapper = styled.div`
  display: flex;
  min-height: calc(100vh - 1rem);
  flex-direction: column;
  justify-content: space-between;
`;

const FullWidth = styled.div`
  width: 100%;
`;

const DevOpsPage = ({ data }) => {
  return (
    <Layout metadata={data.site.siteMetadata}>
      <Nav Root="/" Fixed />
      <FlexWrapper>
        <BodyContainer>
          <h1>Oops, verkeerde afslag</h1>
          <p>
            Je kunt je avontuur hier gerust even pauzeren, óf een stap terug
            zetten om verder te komen.
          </p>
          <Image
            src={data.image.sharp.fluid.src}
            alt="kamperen langs een meer"
          />
        </BodyContainer>
        <FullWidth>
          <Footer />
        </FullWidth>
      </FlexWrapper>
    </Layout>
  );
};

export default DevOpsPage;
